import { ReactNode, useState } from "react"
import { Button, Card, Col, Divider, Row, Table } from "antd"
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { GameProposition } from "../../api";
import { GameInfoCell } from "./EventPreferencesResult";
import { useHttp } from "../../App";


export function EventPreferencesForm(props: { eventId: string, propositions: GameProposition[], onSend: () => any }): (JSX.Element | null) {
    const [selectedGames, setSelectedGames] = useState<string[]>([])
    const [sending, setSending] = useState(false)
    const httpFetch = useHttp()

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const maxPositions = 3

    const sendPropositions = () => {
        if (sending) {
            return
        }
        setSending(true)
        httpFetch(`/api/event/${props.eventId}/preferences`, {
            method: "POST",
            body: JSON.stringify({ gameIds: selectedGames }),
        }).then(() => { setSending(false); props.onSend() })
    }

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setSelectedGames((prev) => {
                const activeIndex = prev.findIndex((i) => i === active.id);
                const overIndex = prev.findIndex((i) => i === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            })
        }
    };

    const selectedData = (selectedGames.map((id) => {
        return props.propositions.find((v) => {
            return v.gameId === id
        })
    }) as GameProposition[]).map((e) => {
        return {
            id: e.gameId,
            data: e,
        }
    })

    return (
        <Card style={{ width: "100%" }}>
            <h1>Twoje preferencje gier. Kolejność istotna, max. {maxPositions}</h1>
            <Divider />
            <Row style={{ minHeight: 440 }}>
                <Col span={12}>
                    <Table
                        pagination={false}
                        showHeader={true}
                        dataSource={props.propositions.filter(e => {
                            return selectedGames.indexOf(e.gameId) === -1
                        }).sort((a, b) => a.name.localeCompare(b.name))}
                        columns={[
                            {
                                width: 1,
                                render: (value, record, index): ReactNode => {
                                    return (
                                        <PlusCircleOutlined style={{ cursor: selectedGames.length >= maxPositions ? "default" : "pointer", opacity: selectedGames.length >= maxPositions ? .2 : 1 }} onClick={() => {
                                            setSelectedGames((prev) => {
                                                if (prev.length >= maxPositions) {
                                                    return prev
                                                }
                                                return ([] as string[]).concat(prev, record.gameId)
                                            })
                                        }} />
                                    )
                                },
                            },
                            {
                                dataIndex: "name",
                                title: "Opcje",
                                render(value, record, index) {
                                    return <GameInfoCell game={record}></GameInfoCell>
                                },
                            },
                        ]}
                        rowKey={"id"}
                    />
                </Col>
                <Col span={12} style={{ borderLeftStyle: "solid", borderLeftColor: "lightgray" }}>
                    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext
                            items={selectedData.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                pagination={false}
                                showHeader={true}
                                components={{
                                    body: {
                                        row: GameRow
                                    }
                                }}
                                rowKey={"id"}
                                dataSource={selectedData}
                                columns={[
                                    {
                                        width: 1,
                                        render(value, record, index) {
                                            return (<MinusCircleOutlined style={{ cursor: "pointer" }} onClick={() => {
                                                setSelectedGames((prev) => {
                                                    return prev.filter((e) => {
                                                        return e !== record.id
                                                    })
                                                })
                                            }} />)
                                        },
                                    },
                                    {
                                        dataIndex: "data",
                                        title: "Preferencje",
                                        render(value, record, index) {
                                            return <GameInfoCell game={record.data}></GameInfoCell>
                                        },
                                    },
                                ]}
                            />
                        </SortableContext>
                    </DndContext>
                </Col>
            </Row>
            <Divider />
            <Button type="primary" onClick={sendPropositions} disabled={sending}>Wyślij</Button>
        </Card>
    )
}


function GameRow(props: any) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    if (!props['data-row-key']) {
        return null
    }

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };
    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
    )
}
