import { Flex, Layout, Menu, Modal, Space } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { CurrentUserContext, useHttp } from "../App";
import { UserOutlined } from "@ant-design/icons";
import { logout } from "../fetch";
import { UserView } from "../api";
import { AuthForm } from "./AuthForm";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Outline() {
  const {currentUser, setCurrentUser} = useContext(CurrentUserContext)
  const httpFetch = useHttp()
  const loadUser = () => {
    httpFetch("/api/users/me").then((resp) => {
        if(resp.ok) {
            resp.json().then((data) => {setCurrentUser( data as UserView)})
        } else {
            setCurrentUser(null)
        }
    })
  }
  useEffect(loadUser, [setCurrentUser, httpFetch])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { t } = useTranslation();

  const userPill = ()  => {
    let items: any[] = []
    if (!currentUser) {
      items = [
        {
          label: t("Log in"),
          icon: <UserOutlined/>,
          key: "login",
          onClick: () => setModalOpen(true)
        }
      ]
    } else{
       items = [
        {
          label: currentUser.username,
          icon: <UserOutlined/>,
          key: "main",
          children: [
            {
              label: (<Link to={"/user/me"}>{t("Profile")}</Link>),
              key: "profile",
            }
            ,
            {
              label: t("Logout"),
              key: "logout",
              onClick: () => {logout().then(() => {setCurrentUser(null)})}
            }
          ]
        }
      ]
    }
    return <Menu items={items} mode="horizontal" theme="dark" selectable={false} disabledOverflow={true}/>
  }
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Flex gap={"middle"} style={{width: "100%"}}>
        <Menu
          theme="dark"
          mode="horizontal"
          items={[]}
          style={{ flex: 1, minWidth: 0 }}
        />
        <Space>
          {userPill()}
        </Space>
        </Flex>
      </Header>
      <Content style={{ padding: 24, textAlign: "center" }}>
        <Space direction="vertical" size={"large"}>
          <Outlet />
        </Space>
      </Content>
      <Modal open={modalOpen} footer={null} onCancel={() => {setModalOpen(false)}} destroyOnClose={true}>
        <AuthForm onLogin={() => {
          loadUser()
          setModalOpen(false)
        }}/>
      </Modal>
    </Layout>
  )
}
