import { Button, Card, Form, Input } from "antd"
import { t } from "i18next"
import { useHttp } from "../App"
import { ChangeEvent, useEffect, useState } from "react"
import { UserView } from "../api"

export function MeProfile(props: {}): (JSX.Element | null) {
    const [user, setUser] = useState<UserView | undefined>(undefined)
    const httpFetch = useHttp()
    const loadUser = () => {
        httpFetch("/api/users/me").then((resp) => {
            if (resp.ok) {
                resp.json().then((data) => { setUser(data as UserView) })
            }
        })
    }
    const submit = () => {
        httpFetch("/api/users/me", {
            method: "PATCH",
            body: JSON.stringify({
                username: user?.username
            })
        })
    }
    const setUsername = (event: ChangeEvent<HTMLInputElement>) => {
        setUser(Object.assign({}, user, { username: event.target.value }))
    }
    useEffect(loadUser, [httpFetch])
    return (
        <Card>
            <h1>Profil</h1>
            <Form.Item
                label={t("Username")}
            >
                <Input value={user?.username} onChange={setUsername} />
            </Form.Item>
            <Button type="primary" size="large" onClick={submit}>{t("Save")}</Button>
        </Card>
    )
}



